exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-beginners-tsx": () => import("./../../../src/pages/beginners.tsx" /* webpackChunkName: "component---src-pages-beginners-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-meetings-tsx": () => import("./../../../src/pages/meetings.tsx" /* webpackChunkName: "component---src-pages-meetings-tsx" */),
  "component---src-pages-members-tsx": () => import("./../../../src/pages/members.tsx" /* webpackChunkName: "component---src-pages-members-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-oldnews-tsx": () => import("./../../../src/pages/oldnews.tsx" /* webpackChunkName: "component---src-pages-oldnews-tsx" */),
  "component---src-pages-past-tsx": () => import("./../../../src/pages/past.tsx" /* webpackChunkName: "component---src-pages-past-tsx" */),
  "component---src-pages-ringwithus-tsx": () => import("./../../../src/pages/ringwithus.tsx" /* webpackChunkName: "component---src-pages-ringwithus-tsx" */)
}

